<template>
	<div>
		<common-table
				:itemScope="['status']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				@search="search"
				@remove="remove"
				@save="save"
				@edit="edit"
				ref="ct"
				:leftConfig="leftConfig"
				:rightConfig="rightConfig"
				:listDeploy="listDeploy"
				pageTitle="一级资源"
				pageKey="code"
				:btnActionEvent="btnActionEvent"
				@otherTableOperate="otherTableOperate"
				@otherRightOperate="otherRightOperate"
        @beforeOpenAddDialog="beforeOpenAddDialog"
		>
			<template v-slot:status="{ item }">
				<w-switches
						inset
						label=""
						color="primary"
						@change="updateStatus(item.sqlid)"
						v-model="item.status" hide-details dense/>
			</template>
		</common-table>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import resource from "./resource.js";
import api from '@/api/common-data.js';

export default {
	mixins: [commonCurd, resource],
	data() {
		return {
			api,
			// 排序字段，不填默认sqlid
			sortname: 'seq',
			leftConfig: [
				{type: 'input', label: '输入一级资源ID/名称', property: 'searchkey'},
				{type: 'select', label: '选择上级分类', property: 'header', items: []}
			],
			rightConfig: [
				// {label: '预设', icon: 'yushe', event: 'import'},
				{label: '新建', icon: 'zengjia', event: 'add'}
			],
			headers: [
				{text: '一级ID', value: 'code'},
				{text: '一级名称', value: 'description'},
				{text: '上级分类', value: 'header'},
				{text: '排序', value: 'seq'},
				{text: '状态', value: 'status'},
				{text: '操作', value: 'actions', width: 120}
			],
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'code',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "一级资源ID"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'description',
						connector: {
							props: {
								required: true,
								label: "一级资源名称"
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'header',
						connector: {
							props: {
								required: true,
								label: "上级分类",
								items: [],
								someToValue: "desc"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'seq',
						connector: {
							props: {
								label: "排序号"
							}
						}
					},
					{
						cols: 3,
						subassembly: "WSwitches",
						disabledDeleteDefault: true,
						property: 'status',
						connector: {
							props: {
								label: '状态',
								inset: true,
								dense: true
							}
						},
						default: true
					},
					{
						cols: 3,
						subassembly: "WSwitches",
						property: 'option',
						connector: {
							props: {
								label: '单选',
								inset: true,
								dense: true
							}
						}
					}
				]
			}
		}
	},
	methods: {
		beforeSave(form) {
			form.type = 'sourceone';
			form.option = form.option ? '1' : '2';
		},
		createdExtend() {
			this.fixedParams.type = 'sourceone';
		},
		// 重写打开编辑的方法
		async edit(sqlid, callback, finallyCallback) {
			let form = await this.getObject(sqlid);
			if (form) {
				this.listDeploy.sqlid = sqlid;
				for (const obj of this.listDeploy.row) {
					let val = form[obj.property];
					if (obj.property === 'option') {
						obj.default = val === '1';
					} else {
						obj.default = val;
					}
					if (obj.hasEditDisabledProperty) {
						obj.connector.props.disabled = true;
					}
				}
				if (callback) {
					callback();
				}
			} else {
				if (finallyCallback) {
					finallyCallback();
				}
			}
		},
    beforeOpenAddDialog(v) {
      if (v === 'add' && this.$refs.ct?.$refs?.leftBtn?.form?.header) {
        this.listDeploy.row[2].default = this.$refs.ct.$refs.leftBtn.form.header
      }
    }
	},
	mounted() {
		// 获取上级分类下拉框数据
		this.getParentResourceMenu('category');
	}
}
</script>

<style scoped lang="scss">

</style>

