export default {
	data() {
		return {
			btnActionEvent: [
				{
					event: 'editItem',
					icon: 'bianji2-fill',
					color: 'primary',
					tooltip: '编辑'
				}
				, {
					event: 'deleteItem',
					icon: 'shanchu-fill',
					color: 'var(--v-redness-base)',
					tooltip: '删除'
				},
				{
					event: 'upMove',
					icon: 'shangyi',
					color: 'primary',
					tooltip: '上移'
				},
				{
					event: 'downMove',
					icon: 'xiayi',
					color: 'primary',
					tooltip: '下移'
				}
			],
		}
	},
	methods: {
		getParentResourceMenu(keyName, header) {
			this.axios.post(this.api.select, {
				"keyname": keyName,
				"params": [
					{
						"key": keyName,
						"value": header
					}
				]
			}).then((res) => {
				if (res.code === this.staticVal.Code.Success) {
					let values = res.data[0].values;
					this.leftConfig[1].items = values;
					this.listDeploy.row[2].connector.props.items = values;
				}
			});
		},
		otherTableOperate(event, item) {
			this.axios.post(this.api.updateSeq, {sqlid: item.sqlid, lup: event === 'upMove'}).then((res) => {
				if (res.code === this.staticVal.Code.Success) {
					this.snackbar.success("更新排序成功");
					this.list();
				}
			});
		},
		otherRightOperate() {
			this.confirm.showConfirm('确定导入预设值吗', '', '导入预设值会先清空当前页面数据').then(() => {
				console.log('确定');
			}).catch(() => {
			});
		},
	}
}
