export default {
    list: "/api/factor/factor_list", // 公共数据列表
    load: "/api/factor/load_factor", // 获取公共数据对象
    save: "/api/factor/save_factor", // 保存公共数据
    remove: "/api/factor/delete_factor", // 删除公共数据
    updateStatus: "/api/factor/change_status", // 更新当前设置状态
    select: '/api/custom/select_data', // 下拉框数据,
    updateSeq: '/api/factor/change_seq', // 更新排序
    listConstruction({
        code = "",
        currentpage = 1,
        pagesize = 50,
        sortanme = "sqlid",
        sortorder = "descending",
        type = ""
    } = {}) {
        return {
            code,
            pages: {
                currentpage,
                pagesize,
                sortanme,
                sortorder
            },
            type
        }
    },
    // 获取下拉框数据参数封装
    getSelectList({params = [], keyname = ""} = {}) {
        return {
            params,
            keyname
        }
    },
    /**
     * 赋值下拉框数据给指定的对象属性，这是按顺序取值的
     * @param {*} obj 要赋值给哪个对象
     * @param {*} keyname 要赋值在指定对象的属性名，属性名使用数组存储
     * @param {*} data 传下拉框接口返回的data
     */
    assignment(obj, keyname, data) {
        // 判断参数1是否是合法的对象类型,同时参数2是否也是数组
        if (obj instanceof Object && keyname instanceof Array) {
            for (let i = 0; i < keyname.length; i++) {
                let value = data.find((n)=> {
                    // console.log(n.keyname,keyname[i])
                    return n.keyname === keyname[i]
                });
                // console.log(value)
                obj[keyname[i]] = value.values;
            }
        }
    }
}
